<template>
  <div>
    <span
      class="grey--text darken-3">
      {{ copyText }}
    </span>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    pathIds: { required: false },
    widgetType: { required: false },
    copyTextId: { required: false }
  },
  data () {
    return {
      copyText: '<script type=\'module\' src=\'http://agendease.com/widgetEmbed.js\'' + '><' + '/script>\n' +
      '<script src=\'http://agendease.com' + this.widgetRoute + '/' + this.path + '><' + '/script>'
    }
  },
  computed: {
    widgetRoute: {
      cache: false,
      get () {
        if (this.widgetType === 'meeting') {
          return '/meeting-list'
        } else if (this.widgetType === 'email') {
          return '/email-signup'
        } else {
          return '/something'
        }
      }
    },
    path: {
      cache: false,
      get () {
        return this.pathIds.clientId + (this.pathIds.boardId ? '/' + this.pathIds.boardId : '')
      }
    }
  },
  mounted () {
    this.$emit('copyTextAndId', { copyText: this.copyText, copyId: this.copyTextId })
  }
})
</script>
