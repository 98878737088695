<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-code-tags</v-icon>
          <h1 class="darkGrey--text">Code Snippets</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Users
            </span>
          </v-tooltip>
        </span>

      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-card class="px-6">
            <v-card-text class="pa-0 pt-2 py-6">

              <v-row class="d-flex justify-space-between">
                <v-col class="d-flex align-center">
                  <span class="secondary--text font-weight-medium text-body-1">
                    Email Notification Sign-up (without meeting list)
                  </span>
                </v-col>
                <v-col cols="2" class="text-right">

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="darkGrey"
                        size="18"
                        v-bind="attrs"
                        v-on="on"
                        v-clipboard:copy="clipboardText.email"
                        v-clipboard:success="copySuccess"
                      >
                        <v-icon size="20">
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Copy code to the clipboard and paste in your website
                      to allow people to sign up for automatic meeting notifications
                  </span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="darkGrey"
                        size="18"
                        v-bind="attrs"
                        v-on="on"
                        @click="openModal('email')"
                      >
                        <v-icon size="20">
                          mdi-eye-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                    Preview
                  </span>
                  </v-tooltip>

                </v-col>
              </v-row>

              <v-card class="grey lighten-3 pa-6" elevation="0">
                  <span class="grey--text darken-3">
                    <code-snippets-text-to-copy widgetType='email' :pathIds="{ clientId: clientId }"  copyTextId='email' @copyTextAndId="displayCopyText" :key="clientId"></code-snippets-text-to-copy>
                  </span>
              </v-card>

            </v-card-text>
        </v-card>

        <v-card class="px-6 mt-10">
          <v-card-text class="pa-0 pt-2 py-6">

            <v-row class="d-flex justify-space-between">
              <v-col class="d-flex align-center">
                  <span class="secondary--text font-weight-medium text-lg-body-1">
                    Meeting List Widget (includes email sign-up if enabled)
                  </span>
              </v-col>
            </v-row>
            <v-container v-if="loading">
              <v-row>
                <v-col
                  cols=12>
                  <v-skeleton-loader
                    class="mx-auto"
                    type="article"
                    light
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols=12>
                  <v-skeleton-loader
                    class="mx-auto"
                    type="article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols=12>
                  <v-skeleton-loader
                    class="mx-auto"
                    type="article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols=12>
                  <v-skeleton-loader
                    class="mx-auto"
                    type="article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-container>
            <div
              v-if="!loading">
              <v-row class="d-flex justify-space-between mt-6">
                <v-col class="d-flex align-center">
                    <span class="font-weight-medium text-body-2 darkGrey--text text-uppercase">
                      All Boards
                    </span>
                </v-col>
                <v-col cols="2" class="text-right">

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="darkGrey"
                        size="18"
                        v-bind="attrs"
                        v-on="on"
                        v-clipboard:copy="clipboardText.meeting_all"
                        v-clipboard:success="copySuccess"
                      >
                        <v-icon size="20">
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Copy code to the clipboard and paste in your website<br>
                      to display the listing of meetings for <b>all boards</b>
                    </span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="darkGrey"
                        size="18"
                        v-bind="attrs"
                        v-on="on"
                        @click="openModal('preview')"
                      >
                        <v-icon size="20">
                          mdi-eye-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Preview and change settings
                    </span>
                  </v-tooltip>

                </v-col>
              </v-row>
              <v-card class="grey lighten-3 pa-6" elevation="0">
                <code-snippets-text-to-copy :pathIds="{ clientId: clientId, boardId: 'all' }" widgetType='meeting' copyTextId='meeting_all' @copyTextAndId="displayCopyText" :key="clientId"></code-snippets-text-to-copy>
              </v-card>
              <template
                v-for="board in boards">
                <v-row class="d-flex justify-space-between mt-6"
                  :key="board.id">
                  <v-col class="d-flex align-center">
                      <span class="font-weight-medium text-body-2 darkGrey--text text-uppercase">
                        {{board.name}}
                        <v-tooltip v-if='board.status==="inactive"' top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="accentYellow"
                              size="18"
                              class="ml-3"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-alert-circle-outline
                            </v-icon>
                          </template>
                          <span>
                            This board is currently inactive.<br>
                            You can revise the code snippet settings for this board while it is inactive.
                          </span>
                        </v-tooltip>
                      </span>
                  </v-col>
                  <v-col cols="2" class="text-right">

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="darkGrey"
                          size="18"
                          v-bind="attrs"
                          v-on="on"
                          v-clipboard:copy="clipboardText['meeting_' + board.id]"
                          v-clipboard:success="copySuccess"
                        >
                          <v-icon size="20">
                            mdi-content-copy
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Copy code to the clipboard and paste in your website<br>
                        to display the listing of meetings for <b>{{board.name}}</b>
                      </span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="darkGrey"
                          size="18"
                          v-bind="attrs"
                          v-on="on"
                          @click="openModal('preview', board)"
                        >
                          <v-icon size="20">
                            mdi-eye-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Preview and change settings
                      </span>
                    </v-tooltip>

                  </v-col>
                </v-row>
                <v-card class="grey lighten-3 pa-6" elevation="0"
                  :key="board.name + '_' + board.id">
                      <code-snippets-text-to-copy :pathIds="{ clientId: clientId, boardId: board.id }" widgetType='meeting' :copyTextId="'meeting_' + board.id" @copyTextAndId="displayCopyText"></code-snippets-text-to-copy>
                </v-card>
              </template>
            </div>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <v-snackbar
      v-model="copied"
      color="secondary"
      bottom
      elevation="0"
      app
    >
      <span class="d-flex align-center justify-center">
        <v-icon>mdi-content-copy</v-icon>
        <span>Code Snippet copied to clipboard</span>
      </span>
    </v-snackbar>

    <code-snippets-preview-email v-model="modals.email"></code-snippets-preview-email>
    <code-snippets-preview :standardTemplate="standardTemplate" :selectedBoard="boardId" :boardName="boardName" :boards="boards" v-model="modals.preview" ></code-snippets-preview>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import CodeSnippetsPreviewEmail from '@/components/CodeSnippets/CodeSnippetsPreviewEmail'
import CodeSnippetsPreview from '@/components/CodeSnippets/CodeSnippetsPreview'
import CodeSnippetsTextToCopy from '@/components/CodeSnippets/CodeSnippetsTextToCopy'
import axios from 'axios'
import BoardWidgetDesignVue from '@/components/Boards/BoardWidgetDesign.vue'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

export default Vue.extend({
  name: 'CodeSnippets',
  components: {
    Portal,
    CodeSnippetsPreviewEmail,
    CodeSnippetsPreview,
    CodeSnippetsTextToCopy
  },
  data: () => ({
    modals: {
      preview: false,
      email: false
    },
    clientId: '',
    standardTemplate: {
      use_custom_design: false,
      replace_with_label: false,
      email_signup: false,
      colors: {
        main: {
          r: 255,
          g: 255,
          b: 255,
          a: 1
        },
        minor: {
          r: 255,
          g: 255,
          b: 255,
          a: 1
        }
      },
      downloadable: {
        agenda: false,
        packet: false,
        minutes: false,
        uploads: false
      }
    },
    boards: '',
    board: '',
    boardId: '',
    boardName: '',
    copyId: '',
    copied: false,
    loading: true,
    clipboardText: {},
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Code Snippets',
        disabled: true,
        href: ''
      }
    ]
  }),
  methods: {
    openModal (item, board) {
      if (board) {
        this.boardId = board.id
        this.boardName = board.name
      } else {
        this.boardId = 'all'
        this.boardName = 'all boards'
      }
      this.modals[item] = true
    },
    displayCopyText (copyText) {
      this.clipboardText[copyText.copyId] = copyText.copyText
    },
    getDataFromApi (showLoading = true) {
      this.loading = showLoading
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/code-snippets/' + this.$store.state.client.activeClient.clientID)
        .then((response) => {
          this.boards = response.data.boards
          this.loading = false
        }).catch(function (error) {
          console.log(error)
        })
    },
    getStandardTemplate () {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/code-snippets/get-template')
        .then((response) => {
          this.standardTemplate = JSON.parse(JSON.stringify(response.data))
          this.getDataFromApi()
        }).catch(function (error) {
          console.log(error)
        })
    },
    copySuccess () {
      this.copied = true
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'client/setActiveClient') {
        if (state.activeClient !== null) {
          this.clientId = this.$store.state.client.activeClient.clientID
          this.getDataFromApi()
        }
      }
    })

    this.getStandardTemplate()
    this.clientId = this.$store.state.client.activeClient.clientID
  }
})
</script>
