<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center align-start headline flex-column mb-6">
        <span>Preview of <span class="font-weight-black">Code Snippet Name</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row class="area-preview d-flex flex-column mt-8 pa-10 rounded">
          <span class="text-uppercase font-weight-medium mb-4 d-flex">
            <span class="darkGrey--text">
              Preview
            </span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="darkGrey"
                  size="18"
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
                The data inside the below table is for layout purposes only
              </span>
            </v-tooltip>

          </span>
          <v-row>
            <v-col class="d-flex justify-space-between">

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="secondary"
                    elevation="0"
                  >
                    <v-icon
                      color="white"
                    >
                      mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>
                  Sign-up
                </span>
              </v-tooltip>

            </v-col>
          </v-row>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'CodeSnippetsPreviewEmail',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
