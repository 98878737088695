<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center align-start headline flex-column mb-6">
        <span>Meeting List Widget Design of <span class="font-weight-black">{{ name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row class="mb-6">
          <v-col class="py-4 px-3">
            <v-switch
              inset
              label="Use custom design"
              color="secondary"
              class="mt-0 pa-0"
              v-model="useCustomDesign"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>

        <v-expand-transition>
            <v-row v-show="useCustomDesign" class="veryLightGrey px-4 py-1">

              <v-col>

                <v-row>
                  <v-col>
                    <v-switch
                      inset
                      label="Allow email sign-up"
                      color="secondary"
                      class="mt-0 pa-0 mb-3"
                      hide-details
                      dense
                      v-model="useEmail"
                    ></v-switch>
                    <v-switch
                      inset
                      label="Replace standard meeting listing with meeting label"
                      color="secondary"
                      class="mt-0 pa-0"
                      hide-details
                      dense
                      v-model="replaceMeeting"
                    ></v-switch>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="d-flex flex-row align-center">
                    <v-menu
                      :close-on-content-click="false"
                      bottom
                      nudge-left="20"
                      nudge-top="20"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="mr-2 d-flex custom-color-picker-btn"
                          v-bind="attrs"
                          v-on="on"
                          :style="updateColorMain">
                        </div>
                      </template>
                      <v-card>
                        <v-card-text class="d-flex flex-column">
                          <span>
                            Main Color
                          </span>
                          <v-color-picker
                            v-model="colorMain"
                            flat
                            hide-canvas
                            class="custom-color-no-alpha"
                            show-swatches
                          ></v-color-picker>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                    <span>Main color</span>
                    <v-menu
                      :close-on-content-click="false"
                      bottom
                      nudge-left="20"
                      nudge-top="20"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="mr-2 ml-6 d-flex custom-color-picker-btn"
                          v-bind="attrs"
                          v-on="on"
                          :style="updateColorMinor"
                        >
                        </div>
                      </template>
                      <v-card>
                        <v-card-text class="d-flex flex-column">
                          <span>
                            Minor Color
                          </span>
                          <v-color-picker
                            v-model="colorMinor"
                            flat
                            hide-canvas
                            class="custom-color-no-alpha"
                            show-swatches
                          ></v-color-picker>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                    <span>Minor color</span>
                  </v-col>
                </v-row>

              </v-col>

              <v-col>
                <v-row>
                  <v-col>
                    <span class="font-weight-bold text-uppercase d-flex mb-4">
                      Downloadable Documents
                    </span>
                    <v-row>
                      <v-col class="py-1">
                        <v-checkbox
                          label="Agenda"
                          color="secondary"
                          hide-details
                          class="ma-0"
                          v-model="downloadable.agenda"
                        ></v-checkbox>
                      </v-col>
                      <v-col class="py-1">
                        <v-checkbox
                          label="Packet"
                          color="secondary"
                          hide-details
                          class="ma-0"
                          v-model="downloadable.packet"
                        ></v-checkbox>
                      </v-col>
                      <v-col class="py-1">
                        <v-checkbox
                          label="Minutes"
                          color="secondary"
                          hide-details
                          class="ma-0"
                          v-model="downloadable.minutes"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-1">
                        <v-checkbox
                          label="Uploaded Documents"
                          color="secondary"
                          hide-details
                          v-model="downloadable.uploaded"
                          class="ma-0"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

              </v-col>
          </v-row>

        </v-expand-transition>

        <v-row class="area-preview d-flex flex-column mt-8 pa-10 rounded">
          <span class="text-uppercase font-weight-medium mb-4 d-flex">
            <span class="darkGrey--text">
              Preview
            </span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="darkGrey"
                  size="18"
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
                The data inside the below table is for layout purposes only
              </span>
            </v-tooltip>

          </span>
          <v-row>
            <v-col class="d-flex justify-space-between">
              <div class="text-center">
                <v-menu
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      :style="updateColorMinor"
                    >
                      2020
                      <v-icon
                        right
                      >
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>2020</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>2019</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>2018</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>2017</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <v-spacer></v-spacer>

              <v-tooltip top v-if="useEmail">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      :style="updateTextColor"
                    >
                      mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>
                  Sign-up
                </span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      :style="updateTextColor"
                    >
                      mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <span>
                  Search
                </span>
              </v-tooltip>

            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card class="overflow-hidden">
                <v-simple-table>
                    <template>
                      <thead :style="updateColorMain">
                      <tr>
                        <th class="text-left white--text">
                          Meeting
                        </th>
                        <th class="text-right white--text">
                          <span v-if="downloadable.agenda || downloadable.packet || downloadable.minutes || downloadable.uploaded">
                            Download
                          </span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <span class="d-flex flex-column py-4">
                            <span v-if="!replaceMeeting">
                              Monday, August 24, 2020 - Regular Meeting
                            </span>
                            <span>
                              Join the meeting with Regular Meeting
                            </span>
                          </span>
                        </td>
                        <td class="text-right">
                          <v-menu
                            top left
                            v-if="downloadable.agenda || downloadable.packet || downloadable.minutes || downloadable.uploaded"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                              >
                                <v-icon
                                  :style="updateTextColor"
                                  size="34"
                                >
                                  mdi-download-circle
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list
                              dense
                            >
                              <v-list-item-group>
                                <v-list-item v-if="downloadable.agenda">
                                  <v-list-item-icon>
                                    <v-icon>mdi-format-list-numbered</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Agenda</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="downloadable.packet">
                                  <v-list-item-icon>
                                    <v-icon>mdi-text-box-multiple-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Packet</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="downloadable.minutes">
                                  <v-list-item-icon>
                                    <v-icon>mdi-fountain-pen-tip</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Minutes</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                  class="mt-2"
                                  v-if="downloadable.uploaded && (downloadable.agenda || downloadable.packet || downloadable.minutes)"
                                ></v-divider>
                                <v-list-item-group v-if="downloadable.uploaded">
                                  <v-subheader>Documents</v-subheader>
                                  <v-list-item>
                                    <v-list-item-title>Audio Recording of Meeting</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-title>Proposed Budget</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-title>Resolution No. R2020-034</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-title>Action Items</v-list-item-title>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list-item-group>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="d-flex flex-column py-4">
                            <span v-if="!replaceMeeting">
                              Monday, August 24, 2020 - Regular Meeting
                            </span>
                            <span>
                              Join the meeting with Regular Meeting
                            </span>
                          </span>
                        </td>
                        <td class="text-right">
                          <v-menu
                            top left
                            v-if="downloadable.agenda || downloadable.packet || downloadable.minutes || downloadable.uploaded"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                              >
                                <v-icon
                                  :style="updateTextColor"
                                  size="34"
                                >
                                  mdi-download-circle
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list
                              dense
                            >
                              <v-list-item-group>
                                <v-list-item v-if="downloadable.agenda">
                                  <v-list-item-icon>
                                    <v-icon>mdi-format-list-numbered</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Agenda</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="downloadable.packet">
                                  <v-list-item-icon>
                                    <v-icon>mdi-text-box-multiple-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Packet</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="downloadable.minutes">
                                  <v-list-item-icon>
                                    <v-icon>mdi-fountain-pen-tip</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Minutes</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                  class="mt-2"
                                  v-if="downloadable.uploaded && (downloadable.agenda || downloadable.packet || downloadable.minutes)"
                                ></v-divider>
                                <v-list-item-group v-if="downloadable.uploaded">
                                  <v-subheader>Documents</v-subheader>
                                  <v-list-item>
                                    <v-list-item-title>Ordinance No. R2020-003</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-title>Action Items</v-list-item-title>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list-item-group>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="d-flex flex-column py-4">
                            <span v-if="!replaceMeeting">
                              Monday, August 24, 2020 - Regular Meeting
                            </span>
                            <span>
                              Join the meeting with Regular Meeting
                            </span>
                          </span>
                        </td>
                        <td class="text-right">
                          <v-menu
                            top left
                            v-if="downloadable.agenda || downloadable.packet || downloadable.minutes || downloadable.uploaded"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                              >
                                <v-icon
                                  :style="updateTextColor"
                                  size="34"
                                >
                                  mdi-download-circle
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list
                              dense
                            >
                              <v-list-item-group>
                                <v-list-item v-if="downloadable.agenda">
                                  <v-list-item-icon>
                                    <v-icon>mdi-format-list-numbered</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Agenda</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="downloadable.packet">
                                  <v-list-item-icon>
                                    <v-icon>mdi-text-box-multiple-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Packet</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="downloadable.minutes">
                                  <v-list-item-icon>
                                    <v-icon>mdi-fountain-pen-tip</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Minutes</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                  class="mt-2"
                                  v-if="downloadable.uploaded && (downloadable.agenda || downloadable.packet || downloadable.minutes)"
                                ></v-divider>
                                <v-list-item-group v-if="downloadable.uploaded">
                                  <v-subheader>Documents</v-subheader>
                                  <v-list-item>
                                    <v-list-item-title>List of speakers at public hearing</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-title>Action Items</v-list-item-title>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list-item-group>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'BoardWidgetDesign',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    boardId: {
      required: false,
      type: Number
    },
    name: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      useCustomDesign: true,
      defaultMain: { r: 30, g: 38, b: 53, a: 1 },
      defaultMinor: { r: 111, g: 198, b: 234, a: 1 },
      colorMain: { r: 30, g: 38, b: 53, a: 1 },
      colorMinor: { r: 111, g: 198, b: 234, a: 1 },
      useEmail: false,
      downloadable: {
        agenda: false,
        packet: false,
        minutes: false,
        uploaded: false
      },
      replaceMeeting: false
    }
  },
  computed: {
    updateColorMain () {
      const { colorMain, defaultMain, useCustomDesign } = this
      if (useCustomDesign) {
        return {
          backgroundColor: 'rgba(' + colorMain.r + ',' + colorMain.g + ',' + colorMain.b + ',1)'
        }
      } else {
        return {
          backgroundColor: 'rgba(' + defaultMain.r + ',' + defaultMain.g + ',' + defaultMain.b + ',1)'
        }
      }
    },
    updateTextColor () {
      const { colorMinor, defaultMinor, useCustomDesign } = this
      if (useCustomDesign) {
        return {
          color: 'rgba(' + colorMinor.r + ',' + colorMinor.g + ',' + colorMinor.b + ',1)'
        }
      } else {
        return {
          color: 'rgba(' + defaultMinor.r + ',' + defaultMinor.g + ',' + defaultMinor.b + ',1)'
        }
      }
    },
    updateColorMinor () {
      const { colorMinor, defaultMinor, useCustomDesign } = this
      if (useCustomDesign) {
        return {
          backgroundColor: 'rgba(' + colorMinor.r + ',' + colorMinor.g + ',' + colorMinor.b + ',1)'
        }
      } else {
        return {
          backgroundColor: 'rgba(' + defaultMinor.r + ',' + defaultMinor.g + ',' + defaultMinor.b + ',1)'
        }
      }
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
