<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center align-start headline flex-column mb-6">
        <span>Preview of the meeting list widget for <span class="font-weight-black">{{ boardName }}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="darkGrey"
                size="18"
                v-bind="attrs"
                v-on="on"
                @click="openModal('preview')"
              >
                <v-icon size="20">
                  mdi-alert-circle-outline
                </v-icon>
              </v-btn>
            </template>
            <span v-if='boardName === "all boards"'>
              This design will apply to the <b>'All Boards'</b> listing as well as the listing for any boards where a custom design is not specified for that board
            </span>
            <span v-else>
              <span v-if="!useCustomDesign">When 'Use custom design' is switched off, <b>{{ boardName}}</b> is using the design for all boards.<br>
              If you change the design for all boards, it will change the design that <b>{{ boardName}}</b> is using also.<br>
              To specify a design specifically for <b>{{ boardName}}</b> (that is independent of the design for all boards), switch on 'Use custom design', and change the settings as you desire.</span>
              <span v-else>This custom design applies to only <b>{{ boardName}}</b>.</span>
            </span>
          </v-tooltip>
        </span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-container v-if="loading">
        <v-row>
          <v-col
            cols=12>
            <v-skeleton-loader
              class="mx-auto"
              type="table-heading, divider, article, divider, table-row@3"
              light
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <div
        v-if="!loading">
      <v-card-text>
        <v-row class="mb-6">
          <v-col class="py-4 px-3">
            <v-switch
              inset
              label="Use custom design"
              color="secondary"
              class="mt-0 pa-0"
              v-model="useCustomDesign"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row v-show="useCustomDesign" class="veryLightGrey px-4 py-1">

            <v-col>

              <v-row>
                <v-col>
                  <v-switch
                    inset
                    label="Allow email sign-up"
                    color="secondary"
                    class="mt-0 pa-0 mb-3"
                    hide-details
                    dense
                    v-model="emailSignupNew"
                  ></v-switch>
                  <v-switch
                    inset
                    label="Replace standard meeting listing with meeting label"
                    color="secondary"
                    class="mt-0 pa-0"
                    hide-details
                    dense
                    v-model="replaceWithLabelNew"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-flex flex-row align-center">
                  <v-menu
                    :close-on-content-click="false"
                    bottom
                    nudge-left="20"
                    nudge-top="20"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="mr-2 d-flex custom-color-picker-btn"
                        v-bind="attrs"
                        v-on="on"
                        :style="colorMainDisplay">
                      </div>
                    </template>
                    <v-card>
                      <v-card-text class="d-flex flex-column">
                          <span>
                            Main Color
                          </span>
                        <v-color-picker
                          v-model="colorMainNew"
                          flat
                          hide-canvas
                          class="custom-color-no-alpha"
                          show-swatches
                        ></v-color-picker>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <span>Main color</span>
                  <v-menu
                    :close-on-content-click="false"
                    bottom
                    nudge-left="20"
                    nudge-top="20"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="mr-2 ml-6 d-flex custom-color-picker-btn"
                        v-bind="attrs"
                        v-on="on"
                        :style="colorMinorDisplay"
                      >
                      </div>
                    </template>
                    <v-card>
                      <v-card-text class="d-flex flex-column">
                          <span>
                            Minor Color
                          </span>
                        <v-color-picker
                          v-model="colorMinorNew"
                          flat
                          hide-canvas
                          class="custom-color-no-alpha"
                          show-swatches
                        ></v-color-picker>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <span>Minor color</span>
                </v-col>
              </v-row>

            </v-col>

            <v-col>
              <v-row>
                <v-col>
                    <span class="font-weight-bold text-uppercase d-flex mb-4">
                      Downloadable Documents
                    </span>
                  <v-row>
                    <v-col class="py-1">
                      <v-checkbox
                        label="Agenda"
                        color="secondary"
                        hide-details
                        class="ma-0"
                        v-model="downloadableAgendaNew"
                      ></v-checkbox>
                    </v-col>
                    <v-col class="py-1">
                      <v-checkbox
                        label="Packet"
                        color="secondary"
                        hide-details
                        class="ma-0"
                        v-model="downloadablePacketNew"
                      ></v-checkbox>
                    </v-col>
                    <v-col class="py-1">
                      <v-checkbox
                        label="Minutes"
                        color="secondary"
                        hide-details
                        class="ma-0"
                        v-model="downloadableMinutesNew"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-1">
                      <v-checkbox
                        label="Uploaded Documents"
                        color="secondary"
                        hide-details
                        v-model="downloadableUploadsNew"
                        class="ma-0"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

            </v-col>
          </v-row>

        </v-expand-transition>

        <v-row class="area-preview d-flex flex-column mt-8 pa-10 rounded">
          <span class="text-uppercase font-weight-medium mb-4 d-flex">
            <span class="darkGrey--text">
              Preview
            </span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="darkGrey"
                  size="18"
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
                The data inside the below list is for preview purposes only. When embedded in your website the list will contain actual current data for your account.
              </span>
            </v-tooltip>

          </span>
          <v-row>
            <v-col class="d-flex justify-space-between">
              <div class="text-center">
                <v-menu
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      :style="colorMinorDisplay"
                    >
                      2020
                      <v-icon
                        right
                      >
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>2020</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>2019</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>2018</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>2017</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <div
                class="text-center ml-5"
                v-if="selectedBoard === 'all'">
                <v-menu
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      :style="colorMinorDisplay"
                    >
                      All Boards
                      <v-icon
                        right
                      >
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>All Boards</v-list-item-title>
                    </v-list-item>
                    <template
                      v-for="board in boards">
                      <v-list-item
                        :key="board.id">
                        <v-list-item-title>{{board.name}}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </div>

              <v-spacer></v-spacer>

              <v-tooltip top v-if="emailSignupDisplay">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      :style="colorTextDisplay"
                    >
                      mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>
                  Sign-up
                </span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      :style="colorTextDisplay"
                    >
                      mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <span>
                  Search
                </span>
              </v-tooltip>

            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card class="overflow-hidden">
                <v-simple-table>
                  <template>
                    <thead :style="colorMainDisplay">
                    <tr>
                      <th class="text-left white--text">
                        Meeting
                      </th>
                      <th class="text-right white--text">
                          <span v-if="downloadableAgendaDisplay || downloadablePacketDisplay || downloadableMinutesDisplay || downloadableUploadsDisplay">
                            Download
                          </span>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                          <span class="d-flex flex-column py-4">
                            <span v-if="!replaceWithLabelDisplay">
                              Monday, August 24, 2020 - Regular Meeting
                            </span>
                            <span>
                              Join the meeting with Regular Meeting
                            </span>
                          </span>
                      </td>
                      <td class="text-right">
                        <v-menu
                          top left
                          v-if="downloadableAgendaDisplay || downloadablePacketDisplay || downloadableMinutesDisplay || downloadableUploadsDisplay"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon
                                :style="colorTextDisplay"
                                size="34"
                              >
                                mdi-download-circle
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list
                            dense
                          >
                            <v-list-item-group>
                              <v-list-item v-if="downloadableAgendaDisplay">
                                <v-list-item-icon>
                                  <v-icon>mdi-format-list-numbered</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Agenda</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="downloadablePacketDisplay">
                                <v-list-item-icon>
                                  <v-icon>mdi-text-box-multiple-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Packet</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="downloadableMinutesDisplay">
                                <v-list-item-icon>
                                  <v-icon>mdi-fountain-pen-tip</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Minutes</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider
                                class="mt-2"
                                v-if="downloadableUploadsDisplay && (downloadableAgendaDisplay || downloadablePacketDisplay || downloadableMinutesDisplay)"
                              ></v-divider>
                              <v-list-item-group v-if="downloadableUploadsDisplay">
                                <v-subheader>Documents</v-subheader>
                                <v-list-item>
                                  <v-list-item-title>Audio Recording of Meeting</v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-title>Proposed Budget</v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-title>Resolution No. R2020-034</v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-title>Action Items</v-list-item-title>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                    <tr>
                      <td>
                          <span class="d-flex flex-column py-4">
                            <span v-if="!replaceWithLabelDisplay">
                              Monday, August 24, 2020 - Regular Meeting
                            </span>
                            <span>
                              Join the meeting with Regular Meeting
                            </span>
                          </span>
                      </td>
                      <td class="text-right">
                        <v-menu
                          top left
                          v-if="downloadableAgendaDisplay || downloadablePacketDisplay || downloadableMinutesDisplay || downloadableUploadsDisplay"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon
                                :style="colorTextDisplay"
                                size="34"
                              >
                                mdi-download-circle
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list
                            dense
                          >
                            <v-list-item-group>
                              <v-list-item v-if="downloadableAgendaDisplay">
                                <v-list-item-icon>
                                  <v-icon>mdi-format-list-numbered</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Agenda</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="downloadablePacketDisplay">
                                <v-list-item-icon>
                                  <v-icon>mdi-text-box-multiple-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Packet</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="downloadableMinutesDisplay">
                                <v-list-item-icon>
                                  <v-icon>mdi-fountain-pen-tip</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Minutes</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider
                                class="mt-2"
                                v-if="downloadableUploadsDisplay && (downloadableAgendaDisplay || downloadablePacketDisplay || downloadableMinutesDisplay)"
                              ></v-divider>
                              <v-list-item-group v-if="downloadableUploadsDisplay">
                                <v-subheader>Documents</v-subheader>
                                <v-list-item>
                                  <v-list-item-title>Ordinance No. R2020-003</v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-title>Action Items</v-list-item-title>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                    <tr>
                      <td>
                          <span class="d-flex flex-column py-4">
                            <span v-if="!replaceWithLabelDisplay">
                              Monday, August 24, 2020 - Regular Meeting
                            </span>
                            <span>
                              Join the meeting with Regular Meeting
                            </span>
                          </span>
                      </td>
                      <td class="text-right">
                        <v-menu
                          top left
                          v-if="downloadableAgendaDisplay || downloadablePacketDisplay || downloadableMinutesDisplay || downloadableUploadsDisplay"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon
                                :style="colorTextDisplay"
                                size="34"
                              >
                                mdi-download-circle
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list
                            dense
                          >
                            <v-list-item-group>
                              <v-list-item v-if="downloadableAgendaDisplay">
                                <v-list-item-icon>
                                  <v-icon>mdi-format-list-numbered</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Agenda</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="downloadablePacketDisplay">
                                <v-list-item-icon>
                                  <v-icon>mdi-text-box-multiple-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Packet</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="downloadableMinutesDisplay">
                                <v-list-item-icon>
                                  <v-icon>mdi-fountain-pen-tip</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Minutes</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider
                                class="mt-2"
                                v-if="downloadableUploadsDisplay && (downloadableAgendaDisplay || downloadablePacketDisplay || downloadableMinutesDisplay)"
                              ></v-divider>
                              <v-list-item-group v-if="downloadableUploadsDisplay">
                                <v-subheader>Documents</v-subheader>
                                <v-list-item>
                                  <v-list-item-title>List of speakers at public hearing</v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-title>Action Items</v-list-item-title>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-row>

      </v-card-text>
      </div>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Close</v-btn>
        <v-btn depressed width="130" @click="saveSettings" class="success">
          <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
          Save Settings</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'CodeSnippetsPreview',
  props: {
    standardTemplate: {},
    selectedBoard: {},
    boardName: String,
    boards: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      saving: false,
      board: {},
      loading: false,
      customCodeSnippets: {},
      useTheseSettings: 'standard', // can be 'standard', 'all', or 'board'
      useCustomDesign: false,
      downloadableAgendaDisplay: false,
      downloadablePacketDisplay: false,
      downloadableMinutesDisplay: false,
      downloadableUploadsDisplay: false,
      emailSignupDisplay: false,
      replaceWithLabelDisplay: false,
      colorMainDisplay: { backgroundColor: 'rgba(255, 255, 255, 1)' },
      colorMinorDisplay: { backgroundColor: 'rgba(255, 255, 255, 1)' },
      colorTextDisplay: { color: 'rgba(255, 255, 255, 1)' },
      codeSnippetSettings: {
        custom: {
          email_signup: null,
          replace_with_label: null,
          downloadable: {
            agenda: null,
            packet: null,
            minutes: null,
            uploads: null
          },
          colors: {
            minor: {
              r: null,
              g: null,
              b: null,
              a: null
            },
            main: {
              r: null,
              g: null,
              b: null,
              a: null
            }
          },
          use_custom_design: false
        },
        notCustom: {
          email_signup: null,
          replace_with_label: null,
          downloadable: {
            agenda: null,
            packet: null,
            minutes: null,
            uploads: null
          },
          colors: {
            minor: {
              r: null,
              g: null,
              b: null,
              a: null
            },
            main: {
              r: null,
              g: null,
              b: null,
              a: null
            }
          },
          use_custom_design: false
        }
      },
      settingsToSave: {
        use_custom_design: true,
        email_signup: null,
        replace_with_label: null,
        downloadable: {
          packet: null,
          agenda: null,
          minutes: null,
          uploads: null
        },
        colors: {
          minor: {
            a: null,
            r: null,
            b: null,
            g: null
          },
          main: {
            a: null,
            r: null,
            b: null,
            g: null
          }
        }
      }
    }
  },
  computed: {
    updateColorMain () {
      if (this.useCustomDesign) {
        return {
          backgroundColor: 'rgba(' + this.codeSnippetSettings.custom.colors.main.r + ',' + this.codeSnippetSettings.custom.colors.main.g + ',' + this.codeSnippetSettings.custom.colors.main.b + ',' + this.codeSnippetSettings.custom.colors.main.a + ')'
        }
      } else {
        return {
          backgroundColor: 'rgba(' + this.codeSnippetSettings.notCustom.colors.main.r + ',' + this.codeSnippetSettings.notCustom.colors.main.g + ',' + this.codeSnippetSettings.notCustom.colors.main.b + ',' + this.codeSnippetSettings.notCustom.colors.main.a + ')'
        }
      }
    },
    updateTextColor () {
      if (this.useCustomDesign) {
        return {
          color: 'rgba(' + this.codeSnippetSettings.custom.colors.minor.r + ',' + this.codeSnippetSettings.custom.colors.minor.g + ',' + this.codeSnippetSettings.custom.colors.minor.b + ',' + this.codeSnippetSettings.custom.colors.minor.a + ')'
        }
      } else {
        return {
          color: 'rgba(' + this.codeSnippetSettings.notCustom.colors.minor.r + ',' + this.codeSnippetSettings.notCustom.colors.minor.g + ',' + this.codeSnippetSettings.notCustom.colors.minor.b + ',' + this.codeSnippetSettings.notCustom.colors.minor.a + ')'
        }
      }
    },
    updateColorMinor () {
      if (this.useCustomDesign) {
        return {
          backgroundColor: 'rgba(' + this.codeSnippetSettings.custom.colors.minor.r + ',' + this.codeSnippetSettings.custom.colors.minor.g + ',' + this.codeSnippetSettings.custom.colors.minor.b + ',' + this.codeSnippetSettings.custom.colors.minor.a + ')'
        }
      } else {
        return {
          backgroundColor: 'rgba(' + this.codeSnippetSettings.notCustom.colors.minor.r + ',' + this.codeSnippetSettings.notCustom.colors.minor.g + ',' + this.codeSnippetSettings.notCustom.colors.minor.b + ',' + this.codeSnippetSettings.notCustom.colors.minor.a + ')'
        }
      }
    },
    downloadableAgendaNew: {
      get () {
        if (this.useCustomDesign) {
          return this.codeSnippetSettings.custom.downloadable.agenda
        } else {
          return this.codeSnippetSettings.notCustom.downloadable.agenda
        }
      },
      set (val) {
        this.downloadableAgendaDisplay = val
        this.settingsToSave.downloadable.agenda = val
      }
    },
    downloadablePacketNew: {
      get () {
        if (this.useCustomDesign) {
          return this.codeSnippetSettings.custom.downloadable.packet
        } else {
          return this.codeSnippetSettings.notCustom.downloadable.packet
        }
      },
      set (val) {
        this.downloadablePacketDisplay = val
        this.settingsToSave.downloadable.packet = val
      }
    },
    downloadableMinutesNew: {
      get () {
        if (this.useCustomDesign) {
          return this.codeSnippetSettings.custom.downloadable.minutes
        } else {
          return this.codeSnippetSettings.notCustom.downloadable.minutes
        }
      },
      set (val) {
        this.downloadableMinutesDisplay = val
        this.settingsToSave.downloadable.minutes = val
      }
    },
    downloadableUploadsNew: {
      get () {
        if (this.useCustomDesign) {
          return this.codeSnippetSettings.custom.downloadable.uploads
        } else {
          return this.codeSnippetSettings.notCustom.downloadable.uploads
        }
      },
      set (val) {
        this.downloadableUploadsDisplay = val
        this.settingsToSave.downloadable.uploads = val
      }
    },
    emailSignupNew: {
      get () {
        if (this.useCustomDesign) {
          return this.codeSnippetSettings.custom.email_signup
        } else {
          return this.codeSnippetSettings.notCustom.email_signup
        }
      },
      set (val) {
        this.emailSignupDisplay = val
        this.settingsToSave.email_signup = val
      }
    },
    replaceWithLabelNew: {
      get () {
        if (this.useCustomDesign) {
          return this.codeSnippetSettings.custom.replace_with_label
        } else {
          return this.codeSnippetSettings.notCustom.replace_with_label
        }
      },
      set (val) {
        this.replaceWithLabelDisplay = val
        this.settingsToSave.replace_with_label = val
      }
    },
    colorMainNew: {
      get () {
        if (this.useCustomDesign) {
          return this.codeSnippetSettings.custom.colors.main
        } else {
          return this.codeSnippetSettings.notCustom.colors.main
        }
      },
      set (val) {
        this.colorMainDisplay = { backgroundColor: 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')' }
        this.settingsToSave.colors.main = val
      }
    },
    colorMinorNew: {
      get () {
        if (this.useCustomDesign) {
          return this.codeSnippetSettings.custom.colors.minor
        } else {
          return this.codeSnippetSettings.notCustom.colors.minor
        }
      },
      set (val) {
        this.colorMinorDisplay = { backgroundColor: 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')' }
        this.colorTextDisplay = { color: 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')' }
        this.settingsToSave.colors.minor = val
      }
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    allBoardsSnippetSettings: {
      get () {
        return this.customCodeSnippets.settings.all
      }
    },
    activeBoards () {
      const activeBoards = []
      for (const board in this.boards) {
        if (this.boards[board].status === 'active') {
          activeBoards.push(this.boards[board])
        }
      }

      return activeBoards
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.loading = true
        this.getDataFromApi()
      } else {
        //
      }
    },
    downloadableAgendaNew (val) {
      this.downloadableAgendaDisplay = val
      this.settingsToSave.downloadable.agenda = val
    },
    downloadablePacketNew (val) {
      this.downloadablePacketDisplay = val
      this.settingsToSave.downloadable.packet = val
    },
    downloadableMinutesNew (val) {
      this.downloadableMinutesDisplay = val
      this.settingsToSave.downloadable.minutes = val
    },
    downloadableUploadsNew (val) {
      this.downloadableUploadsDisplay = val
      this.settingsToSave.downloadable.uploads = val
    },
    emailSignupNew (val) {
      this.emailSignupDisplay = val
      this.settingsToSave.email_signup = val
    },
    replaceWithLabelNew (val) {
      this.replaceWithLabelDisplay = val
      this.settingsToSave.replace_with_label = val
    },
    colorMainNew (val) {
      this.colorMainDisplay = { backgroundColor: 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')' }
      this.settingsToSave.colors.main = val
    },
    colorMinorNew (val) {
      this.colorMinorDisplay = { backgroundColor: 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')' }
      this.colorTextDisplay = { color: 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')' }
      this.settingsToSave.colors.minor = val
    }
  },
  methods: {
    getDataFromApi (showLoading = true) {
      this.loading = showLoading
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/code-snippets/' + this.$store.state.client.activeClient.clientID)
        .then((response) => {
          this.customCodeSnippets = response.data.snippets
          this.loading = false
          this.setSnippetSettings()
        }).catch(function (error) {
          console.log(error)
        })
    },
    setSnippetSettings () {
      if (this.selectedBoard) {
        // Default to 'standard' for notCustom
        this.codeSnippetSettings.notCustom = this.standardTemplate
        this.useCustomDesign = false
        if (!this.customCodeSnippets) {
          this.codeSnippetSettings.custom = this.standardTemplate
        } else {
          // Continue if there is a custom settings record
          if (this.selectedBoard === 'all') {
            if (this.customCodeSnippets.settings.all) {
              this.codeSnippetSettings.custom = this.customCodeSnippets.settings.all
              this.useCustomDesign = this.customCodeSnippets.settings.all.use_custom_design
            } else {
              this.codeSnippetSettings.custom = this.standardTemplate
            }
          } else {
            this.board = this.selectedBoard

            if (this.customCodeSnippets.settings.boards[this.selectedBoard]) {
              this.codeSnippetSettings.custom = this.customCodeSnippets.settings.boards[this.selectedBoard]
              this.useCustomDesign = this.customCodeSnippets.settings.boards[this.selectedBoard]

              if (this.customCodeSnippets.settings.all) {
                if (this.customCodeSnippets.settings.all.use_custom_design) {
                  this.codeSnippetSettings.notCustom = this.customCodeSnippets.settings.all
                }
              }
            } else if (this.customCodeSnippets.settings.all) {
              if (this.customCodeSnippets.settings.all.use_custom_design) {
                this.codeSnippetSettings.custom = this.customCodeSnippets.settings.all
                this.codeSnippetSettings.notCustom = this.customCodeSnippets.settings.all
              } else {
                this.codeSnippetSettings.custom = this.standardTemplate
              }
            } else {
              this.codeSnippetSettings.custom = this.standardTemplate
            }
          }
        }
      }
    },
    formattedForSaving () {
      // If client does not already have a snippet settings record make the object to add these settings to it.
      if (this.customCodeSnippets === null) {
        this.customCodeSnippets = {
          client_id: this.$store.state.client.activeClient.clientID,
          settings: {}
        }
      }

      // Now update with the revised settings
      if (this.selectedBoard === 'all') {
        // Add the 'all' settings
        this.customCodeSnippets.settings.all = this.settingsToSave
        this.customCodeSnippets.settings.all.use_custom_design = this.useCustomDesign
      } else {
        // If the 'boards' object doesn't exist, add it now
        if (!this.customCodeSnippets.settings.boards) {
          this.customCodeSnippets.settings.boards = {}
        }

        // Add the board settings
        this.customCodeSnippets.settings.boards[this.selectedBoard] = this.settingsToSave
        this.customCodeSnippets.settings.boards[this.selectedBoard].use_custom_design = this.useCustomDesign
      }

      return true
    },
    saveSettings () {
      this.saving = true
      if (this.formattedForSaving()) {
        axios.put(process.env.VUE_APP_BACKEND_URL + '/api/code-snippets/' + this.$store.state.client.activeClient.clientID, this.customCodeSnippets)
          .then((response) => {
            this.saving = false
            this.show = false
            this.$emit('reload', true)
          })
      }
    },
    cleanUp () {
      this.useTheseSettings = 'standard'
    }
  },
  updated () {
    if (this.show) {
    } else {
      this.cleanUp()
    }
  }
})
</script>
