var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-breadcrumbs',{staticClass:"pa-0 mb-8",attrs:{"items":_vm.breadcrumbsItems},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"d-flex align-center justify-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"secondary","size":"36"}},[_vm._v("mdi-code-tags")]),_c('h1',{staticClass:"darkGrey--text"},[_vm._v("Code Snippets")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"darkGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" Explanation for Users ")])])],1)])],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"px-6"},[_c('v-card-text',{staticClass:"pa-0 pt-2 py-6"},[_c('v-row',{staticClass:"d-flex justify-space-between"},[_c('v-col',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"secondary--text font-weight-medium text-body-1"},[_vm._v(" Email Notification Sign-up (without meeting list) ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.clipboardText.email),expression:"clipboardText.email",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copySuccess),expression:"copySuccess",arg:"success"}],attrs:{"icon":"","color":"darkGrey","size":"18"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-content-copy ")])],1)]}}])},[_c('span',[_vm._v(" Copy code to the clipboard and paste in your website to allow people to sign up for automatic meeting notifications ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"darkGrey","size":"18"},on:{"click":function($event){return _vm.openModal('email')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-eye-outline ")])],1)]}}])},[_c('span',[_vm._v(" Preview ")])])],1)],1),_c('v-card',{staticClass:"grey lighten-3 pa-6",attrs:{"elevation":"0"}},[_c('span',{staticClass:"grey--text darken-3"},[_c('code-snippets-text-to-copy',{key:_vm.clientId,attrs:{"widgetType":"email","pathIds":{ clientId: _vm.clientId },"copyTextId":"email"},on:{"copyTextAndId":_vm.displayCopyText}})],1)])],1)],1),_c('v-card',{staticClass:"px-6 mt-10"},[_c('v-card-text',{staticClass:"pa-0 pt-2 py-6"},[_c('v-row',{staticClass:"d-flex justify-space-between"},[_c('v-col',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"secondary--text font-weight-medium text-lg-body-1"},[_vm._v(" Meeting List Widget (includes email sign-up if enabled) ")])])],1),(_vm.loading)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"article","light":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"article"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"article"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"article"}})],1)],1)],1):_vm._e(),(!_vm.loading)?_c('div',[_c('v-row',{staticClass:"d-flex justify-space-between mt-6"},[_c('v-col',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-medium text-body-2 darkGrey--text text-uppercase"},[_vm._v(" All Boards ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.clipboardText.meeting_all),expression:"clipboardText.meeting_all",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copySuccess),expression:"copySuccess",arg:"success"}],attrs:{"icon":"","color":"darkGrey","size":"18"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,false,3573649171)},[_c('span',[_vm._v(" Copy code to the clipboard and paste in your website"),_c('br'),_vm._v(" to display the listing of meetings for "),_c('b',[_vm._v("all boards")])])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"darkGrey","size":"18"},on:{"click":function($event){return _vm.openModal('preview')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-eye-outline ")])],1)]}}],null,false,3718658248)},[_c('span',[_vm._v(" Preview and change settings ")])])],1)],1),_c('v-card',{staticClass:"grey lighten-3 pa-6",attrs:{"elevation":"0"}},[_c('code-snippets-text-to-copy',{key:_vm.clientId,attrs:{"pathIds":{ clientId: _vm.clientId, boardId: 'all' },"widgetType":"meeting","copyTextId":"meeting_all"},on:{"copyTextAndId":_vm.displayCopyText}})],1),_vm._l((_vm.boards),function(board){return [_c('v-row',{key:board.id,staticClass:"d-flex justify-space-between mt-6"},[_c('v-col',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-medium text-body-2 darkGrey--text text-uppercase"},[_vm._v(" "+_vm._s(board.name)+" "),(board.status==="inactive")?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"accentYellow","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" This board is currently inactive."),_c('br'),_vm._v(" You can revise the code snippet settings for this board while it is inactive. ")])]):_vm._e()],1)]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.clipboardText['meeting_' + board.id]),expression:"clipboardText['meeting_' + board.id]",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copySuccess),expression:"copySuccess",arg:"success"}],attrs:{"icon":"","color":"darkGrey","size":"18"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Copy code to the clipboard and paste in your website"),_c('br'),_vm._v(" to display the listing of meetings for "),_c('b',[_vm._v(_vm._s(board.name))])])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"darkGrey","size":"18"},on:{"click":function($event){return _vm.openModal('preview', board)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Preview and change settings ")])])],1)],1),_c('v-card',{key:board.name + '_' + board.id,staticClass:"grey lighten-3 pa-6",attrs:{"elevation":"0"}},[_c('code-snippets-text-to-copy',{attrs:{"pathIds":{ clientId: _vm.clientId, boardId: board.id },"widgetType":"meeting","copyTextId":'meeting_' + board.id},on:{"copyTextAndId":_vm.displayCopyText}})],1)]})],2):_vm._e()],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":"secondary","bottom":"","elevation":"0","app":""},model:{value:(_vm.copied),callback:function ($$v) {_vm.copied=$$v},expression:"copied"}},[_c('span',{staticClass:"d-flex align-center justify-center"},[_c('v-icon',[_vm._v("mdi-content-copy")]),_c('span',[_vm._v("Code Snippet copied to clipboard")])],1)]),_c('code-snippets-preview-email',{model:{value:(_vm.modals.email),callback:function ($$v) {_vm.$set(_vm.modals, "email", $$v)},expression:"modals.email"}}),_c('code-snippets-preview',{attrs:{"standardTemplate":_vm.standardTemplate,"selectedBoard":_vm.boardId,"boardName":_vm.boardName,"boards":_vm.boards},model:{value:(_vm.modals.preview),callback:function ($$v) {_vm.$set(_vm.modals, "preview", $$v)},expression:"modals.preview"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }